import { tifaApi } from "services/api";
import { action, makeAutoObservable } from "mobx";
import { RootStore } from "./RootStore";

interface IInvestmentProduct {
  key: string;
}

interface IInvestmentBalance {
  amount: number;
  cost: number;
  id: number;
  investmentProduct: IInvestmentProduct;
}

interface IPool {
  id: number;
  usd: number;
  hkd: number;
  rmb: number;
  name: string;

  balances: IInvestmentBalance[];
}

export class BalanceStore {
  pools: IPool[] = [];
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  @action
  getPools = async () => {
    const { data } = await tifaApi.get("/user-balance/pools");
    this.pools = data;
  };
}
