import React, { useCallback, useState } from "react";
import { PropsWithStores } from "stores/RootStore";
import { withAllStores } from "stores/withAllStores";

interface Props extends PropsWithStores {}

const Auth = ({ authStore }: Props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameOnChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handlePasswordOnChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);

  const handleLogin = useCallback(
    (event) => {
      event.preventDefault();

      authStore.login({
        username,
        password,
      });
    },
    [authStore, username, password]
  );

  return (
    <div>
      <h4>Please Login</h4>
      <form onSubmit={handleLogin}>
        <div>
          <input value={username} onChange={handleUsernameOnChange} />
        </div>
        <div>
          <input
            type="password"
            value={password}
            onChange={handlePasswordOnChange}
          />
        </div>
        <button type="submit">Login</button>
        <button type="reset">Reset</button>
      </form>
    </div>
  );
};

export default withAllStores(Auth);
