import React from "react";
import { PropsWithStores } from "stores/RootStore";
import { withAllStores } from "stores/withAllStores";

interface Props extends PropsWithStores {}

const Wallet = ({ balanceStore, priceStore }: Props) => {
  return (
    <div>
      {balanceStore.pools.map((pool) => {
        const currentUSD = pool.usd;
        let balanceCount = 0;
        pool.balances.forEach((balance) => {
          const investmentProductCurrentPrice =
            priceStore.tickerHash[balance.investmentProduct?.key]?.price;
          balanceCount =
            balanceCount + investmentProductCurrentPrice * balance.amount;
        });
        return (
          <div>
            <h4>{pool.name}</h4>

            <h5>Total: {currentUSD + balanceCount}</h5>

            <p>USD: {pool.usd}</p>
            <h5>Investment products</h5>
            <table>
              <thead>
                <td>Product</td>
                <td>Amount</td>
                <td>Average Cost</td>
                <td>P/L</td>
              </thead>
              <tbody>
                {pool.balances.map((balance) => {
                  const realTimePrice =
                    priceStore.tickerHash[balance.investmentProduct?.key]?.price;
                  const profit =
                    (realTimePrice - balance.cost) * balance.amount;
                  return (
                    <tr>
                      <td>{balance.investmentProduct?.key}</td>{" "}
                      <td>{balance.amount}</td>
                      <td>{balance.cost}</td>
                      <td>
                        {`${profit.toFixed(2)} ${(
                          (profit / (balance.cost * balance.amount)) *
                          100
                        ).toFixed(2)}%`}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
};

export default withAllStores(Wallet);
