import { action, makeAutoObservable, observable } from "mobx";
import { tifaApi } from "services/api";
import { ITicker } from "./PriceStore";
import { RootStore } from "./RootStore";

interface IOrderForm {
  price: number;
  amount: number;
  investmentProductKey: string;
}

interface IOrder {
  id: number;
  price: number;
  amount: number;
  direction: string;
}

export class OrderStore {
  @observable form: IOrderForm = {
    price: 0,
    amount: 0,
    investmentProductKey: "",
  };

  @observable orders: IOrder[] = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
  }

  @action
  setFormField = (field: string, value: any) => {
    this.form = {
      ...this.form,
      [field]: value,
    };
  };

  @action
  placeBuyOrder = () => {
    this.placeOrder("BUY");
  };

  @action
  placeSellOrder = () => {
    this.placeOrder("SELL");
  };

  @action
  placeOrder = async (direction: "BUY" | "SELL") => {
    await tifaApi.post("/order", {
      direction: direction,
      investmentProductKey: this.form.investmentProductKey,
      amount: this.form.amount,
      price: this.form.price,

      // TODO change to default user pool
      poolId: this.rootStore.balanceStore.pools[0]?.id,
    });
    this.getOrders(
      this.rootStore.balanceStore.pools[0]?.id,
      this.form.investmentProductKey
    );
  };

  @action
  selectStock = (ticker: ITicker) => {
    this.form.investmentProductKey = ticker.key;
    this.form.price = ticker.price;
  };

  @action
  getOrders = async (poolId: number, investmentProductKey: string) => {
    const { data } = await tifaApi.get("/order", {
      params: {
        poolId,
        investmentProductKey,
      },
    });

    this.orders = data[0];
  };
}
