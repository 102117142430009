import { AuthStore } from "./AuthStore";
import { BalanceStore } from "./BalanceStore";
import { PriceStore } from "./PriceStore";
import { OrderStore } from "./OrderStore";

export class RootStore {
  authStore: AuthStore;
  priceStore: PriceStore;
  balanceStore: BalanceStore;
  orderStore: OrderStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.priceStore = new PriceStore(this);
    this.balanceStore = new BalanceStore(this);
    this.orderStore = new OrderStore(this);
  }
}

export interface PropsWithStores {
  authStore: AuthStore;
  priceStore: PriceStore;
  balanceStore: BalanceStore;
  orderStore: OrderStore;
}

export default new RootStore();
