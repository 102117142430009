let socket


export const startSocket = () => {
  if (!socket) {
    socket = require("socket.io-client")("https://tifa-api.zhuxin.ai", {
      path: "/websocket/socket.io",
      transports: ["websocket"],
    });
  }

  return socket;
  
  // return require("socket.io-client")("http://localhost:8888", {
  //   transports: ["websocket"],
  // });
};

// const io = require('socket.io-client');
// export const startSocket = () => {
//   const socket = io.connect('http://8.210.13.118:8888', {
//     path: '/ws',
//     transports: ['websocket'],
//     forceNew: true,
//   });

//   console.log(socket);
//   return socket;
// };
