import { tifaApi } from "services/api";
import { observable, computed, action, makeAutoObservable } from "mobx";
import localforage from "localforage";
import { RootStore } from "./RootStore";

const TIFA_TOKEN = "TIFA_TOKEN";

export type IAuthStep = "login" | "signup" | "forget-password" | "confirm";

export interface ITifaUser {
  username: string;
  email: string;
  access_token: string;
}

export class AuthStore {
  @observable user: ITifaUser | null = null;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
    this.init();
  }

  async init() {}

  @computed
  get isUserLoggedIn(): boolean {
    return !!this.user;
  }

  @action
  checkLocalToken = async () => {
    try {
      const token = await localforage.getItem(TIFA_TOKEN);
      if (!!token) {
        await this.setApiHeadersWithToken(token as string);
      }
    } catch (err) {}
  };

  @action
  checkAuth = async () => {
    try {
      await this.checkLocalToken();
      const { data: user } = await tifaApi.get("/auth/profile");
      this.onUserLogin(user);
      return true;
    } catch {
      return false;
    }
  };

  @action
  login = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const { data: user } = await tifaApi.post("/auth/login", {
        username: username,
        password: password,
      });
      this.onUserLogin(user);
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };

  @action
  register = async ({
    username,
    password,
    email,
  }: {
    username: string;
    password: string;
    email: string;
  }) => {
    try {
      const registerUser = {
        username,
        password,
        email,
      };
      const { data: user } = await tifaApi.post("/auth/register", registerUser);
      this.onUserLogin(user);
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };

  @action
  googleLogin = async (idToken: string) => {
    try {
      const res = await tifaApi.post("/auth/social-login/google", {
        socialInfo: { idToken },
      });
      this.onUserLogin(res.data);
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };

  @action
  appleLogin = () => {};

  @action
  onUserLogin = async (user: ITifaUser) => {
    this.user = user;

    if (!!user?.access_token) {
      await this.setApiHeadersWithToken(user?.access_token);
    }

    this.rootStore.priceStore.init();
    this.rootStore.balanceStore.getPools();
  };

  @action
  setApiHeadersWithToken = async (token?: string) => {
    console.log("Setting Token", token);
    if (!!token) {
      tifaApi.defaults.headers.common.Authorization = `Bearer ${token}`;
      await localforage.setItem(TIFA_TOKEN, token);
    } else {
      delete tifaApi.defaults.headers.common.Authorization;
      await localforage.removeItem(TIFA_TOKEN);
    }
  };

  @action
  startForgetPassword = async (yourEmail: string) => {
    try {
      await tifaApi.post("/auth/forget-password", {
        email: yourEmail,
      });
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };

  @action
  checkForgetPasswordToken = async (yourToken: string) => {
    try {
      await tifaApi.post("/auth/forget-password/token", {
        token: yourToken,
      });
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };
  @action
  confirmForgetPassword = async (yourToken: string, yourPassword: string) => {
    try {
      await tifaApi.post("/auth/forget-password/confirm", {
        token: yourToken,
        password: yourPassword,
      });
      return true;
    } catch (err) {
      return err?.response?.data?.message || "";
    }
  };

  @action
  logout = async () => {
    await this.setApiHeadersWithToken();
    this.user = null;
  };
}
