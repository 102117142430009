import React, { useCallback, useEffect } from "react";
import { withAllStores } from "stores/withAllStores";
import { PropsWithStores } from "stores/RootStore";

interface Props extends PropsWithStores {}

const PlaceOrder = ({ orderStore, balanceStore }: Props) => {
  const handleChange = useCallback(
    (event) => {
      orderStore.setFormField(event.target.name, event.target.value);
    },
    [orderStore]
  );

  const handleBuy = useCallback(
    (event) => {
      event.preventDefault();
      orderStore.placeBuyOrder();
    },
    [orderStore]
  );
  const handleSell = useCallback(
    (event) => {
      event.preventDefault();
      orderStore.placeSellOrder();
    },
    [orderStore]
  );

  useEffect(() => {
    orderStore.getOrders(
      balanceStore.pools[0]?.id,
      orderStore.form.investmentProductKey
    );
  }, [orderStore, balanceStore.pools, orderStore.form.investmentProductKey]);

  return (
    <>
      <form className="PlaceOrder">
        <label>Investment Product</label>
        <input
          disabled={true}
          onChange={handleChange}
          value={orderStore.form.investmentProductKey}
          name="investmentProductKey"
        ></input>

        <label>Price</label>
        <input
          type="number"
          onChange={handleChange}
          value={orderStore.form.price}
          name="price"
        ></input>

        <label>Amount</label>
        <input
          type="number"
          onChange={handleChange}
          value={orderStore.form.amount}
          name="amount"
        ></input>

        <button onClick={handleBuy}>Buy</button>
        <button onClick={handleSell}>Sell</button>
      </form>

      <table>
        <thead>
          <tr>
            <td>Order Id</td>
            <td>Order Amount</td>
            <td>Order Price</td>
            <td>Order Direction</td>
          </tr>
        </thead>

        <tbody>
          {(orderStore.orders || []).map((order) => {
            return (
              <tr>
                <td>{order.id}</td>
                <td>{order.amount}</td>
                <td>{order.price}</td>
                <td>{order.direction}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default withAllStores(PlaceOrder);
