import PlaceOrder from "components/PlaceOrder";
import Wallet from "components/Wallet";
import React, { useEffect } from "react";
import { PropsWithStores } from "stores/RootStore";
import { withAllStores } from "stores/withAllStores";
import Auth from "./Auth";

interface Props extends PropsWithStores {}

const AuthWrapper: React.FunctionComponent<Props> = ({ authStore }) => {
  useEffect(() => {
    // First load
    authStore.checkAuth();
  }, [authStore]);

  return (
    <div>
      {authStore.isUserLoggedIn && (
        <div>
          <h3>Logged in as {authStore?.user?.username}</h3>
          <button onClick={authStore.logout}>logout</button>
          <PlaceOrder />
          <Wallet />
        </div>
      )}
      {!authStore.isUserLoggedIn && <Auth />}
    </div>
  );
};

export default withAllStores(AuthWrapper);
