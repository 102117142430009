import { startSocket } from "services/socket";
import { action, computed, makeAutoObservable, runInAction } from "mobx";
import { Socket } from "socket.io-client";
import moment from "moment";
import { RootStore } from "./RootStore";

export interface ITicker {
  key: string;
  price: number;
  changePercent: number;
  change: number;
}

export interface ITickerEvent {
  ticker: ITicker;
}

interface ITickerHash {
  [key: string]: ITicker;
}

export interface ITickerInitialDataEvent {
  [key: string]: ITicker;
}

export class PriceStore {
  socket: Socket;
  tickerHash: ITickerHash = {};
  tickerLastUpdate: string;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    this.rootStore = rootStore;
    this.init();
  }

  init = () => {
    this.socket = startSocket();
    this.socket.on("connect", () => {
      this.socket.emit("join-receiver");
    });
    this.socket.on("initialData", this.handleInitialData);
    this.socket.on("cryptopair", this.handleTicker);
    this.socket.on("stock", this.handleTicker);
  };

  @action
  handleInitialData = (event: ITickerInitialDataEvent) => {
    Object.keys(event).forEach((tickerKey) => {
      if (!!event[tickerKey].key) {
        this.tickerHash = {
          ...this.tickerHash,
          [tickerKey]: {
            ...event[tickerKey],
          },
        };
      } else {
        this.tickerHash = {
          ...this.tickerHash,
          [tickerKey]: {
            key: tickerKey,
            price: 0,
            change: 0,
            changePercent: 0,
          },
        };
      }
    });
    this.tickerLastUpdate = moment().format("MM/DD HH:mm:ss");
  };

  @action
  handleTicker = (event: ITickerEvent) => {
    runInAction(() => {
      this.tickerHash = {
        ...this.tickerHash,
        [event.ticker.key]: {
          ...event.ticker,
        },
      };
      this.tickerLastUpdate = moment().format("MM/DD HH:mm:ss");
    });
  };

  @computed
  get tickers(): ITicker[] {
    return Object.values(this.tickerHash);
  }
}
