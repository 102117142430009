import {inject, observer} from 'mobx-react';
import {IReactComponent} from 'mobx-react/dist/types/IReactComponent';

export const withAllStores = (OriginalComponent: IReactComponent) => {
  return inject(
    'authStore',
    'priceStore',
    'balanceStore',
    'orderStore'
  )(observer(OriginalComponent));
};
