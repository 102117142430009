import AuthWrapper from "components/AuthWrapper";
import Stock from "components/Stock";
import { Provider } from "mobx-react";
import React from "react";
import rootStore from "stores/RootStore";
import "./App.css";

function App() {
  return (
    <Provider {...rootStore}>
      <div className="AppContainer">
        <Stock />
        <AuthWrapper />
      </div>
    </Provider>
  );
}

export default App;
