import React, { useCallback } from "react";
import { PropsWithStores } from "stores/RootStore";
import { withAllStores } from "stores/withAllStores";

interface Props extends PropsWithStores {}

const Stock: React.FunctionComponent<Props> = ({ priceStore, orderStore }) => {
  const handleSelectTicker = useCallback(
    (ticker) => () => {
      orderStore.selectStock(ticker);
    },
    [orderStore]
  );

  return (
    <div>
      <h4>Stock List And Ticker</h4>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Price</td>
            <td>Change</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {priceStore?.tickers.map((ticker) => {
            return (
              <tr>
                <td>{ticker.key}</td>
                <td>{ticker.price.toFixed(2)}</td>
                <td>
                  {ticker.change.toFixed(2)} (
                  {(ticker.changePercent * 100).toFixed(2)}
                  %)
                </td>
                <td>
                  <button onClick={handleSelectTicker(ticker)}>Select</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default withAllStores(Stock);
